<script setup lang="ts">
const userStore = useUser()
const visible = ref<boolean>(false)
const loading = ref<boolean>(false)
const router = useRouter()
async function handleLogout() {
  try {
    loading.value = true
    await userStore.handleLogout(1)

    visible.value = false
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }
}

defineExpose({
  visible
})
</script>

<template>
  <BaseDialog v-model="visible" dialog-class="w-84 lg:w-120">
    <div flex flex-col justify-between items-center mxa mb-7.5 mt-7.5 w-66 lg:w-96>
      <p mb-4 font-size-8 font-600>退出登录</p>
      <p font-size-4 op50>退出登录后将无法查看个人中心观看历史和收藏视频。</p>
      <BaseButton text="确定" round mb-4 mt-10 w-full :loading="loading" @click="handleLogout()" />
    </div>
  </BaseDialog>
</template>
