<script setup lang="ts">
import { getPlayCollectVideo } from '~/api/user'
import { setCanceUserCollection } from '~/api/detail'
import { UserBindPhone, UserLogoutDialog } from '#components'

definePageMeta({
  middleware: ['user']
})

const userStore = useUser()
const activatiType = ref(1)
const bindPhoneRef = ref<InstanceType<typeof UserBindPhone> | null>(null)
const logoutDialogRef = ref<InstanceType<typeof UserLogoutDialog> | null>(null)
const isLoading = ref<boolean>(true)
const totalPage = ref(0)
const playCollectData = ref<any>([])
// const isMobile = computed(() => {
//   return useDevice().isMobile
// })
// 绑定手机
function handleBindPhone() {
  if (bindPhoneRef.value) {
    bindPhoneRef.value.visible = true
  }
}
// 打开退出弹窗
function handleLogoutDialog() {
  if (logoutDialogRef.value) {
    logoutDialogRef.value.visible = true
  }
}
async function getPlayCollectVideoData(page: number, flag?: boolean) {
  try {
    const { data } = await getPlayCollectVideo({ pageNum: page, pageSize: 20, userFlag: activatiType.value })
    totalPage.value = data.totalRecord
    if (flag) {
      playCollectData.value = data?.record || []
    } else {
      playCollectData.value.push(...data.record)
    }
    isLoading.value = false
  } catch (error) {
    isLoading.value = false
  }
}
getPlayCollectVideoData(1)

async function handleListData(val: number) {
  isLoading.value = true
  activatiType.value = val
  totalPage.value = 0
  playCollectData.value = []
  await getPlayCollectVideoData(1)
}

async function getPlayCollectNewData(page: number) {
  if (playCollectData.value.length) {
    await getPlayCollectVideoData(page)
  }
}

async function handleDelete(val: any) {
  isLoading.value = true
  await setCanceUserCollection({ userFlag: activatiType.value, videoId: val.videoSiteId })
  playCollectData.value = playCollectData.value.filter((item: any) => item.videoSiteId !== val.videoSiteId)
  totalPage.value = totalPage.value - 1
  isLoading.value = false
}
</script>

<template>
  <div class="font-size-3">
    <div v-if="userStore.isRealUser" class="flex items-center flex-justify-between">
      <ul class="flex">
        <li class="mr-5">
          <NuxtImg
            v-if="userStore.userInfo.topImagesUrl"
            hover="~ op75"
            :src="`${userStore.userInfo.topImagesUrl}`"
            h-15
            cursor-pointer
            transition
            duration-150
            lg:h-15
            lg:w-15
          />
        </li>
        <li class="flex flex-col flex-justify-around">
          <span class="p-l-2"> {{ userStore.userInfo.userName }} </span>
          <button v-if="!userStore.userInfo.mobile" class="border-rd-5 p-l-2 p-r-2 bg-f97316" @click="handleBindPhone">
            绑定手机号
          </button>
        </li>
      </ul>
      <BaseButton type="info" text="退出" round h6 w-20 p-0.5 lg:hidden @click="handleLogoutDialog" />
    </div>
    <div mt-5 border-rd-2 bg-1b2138>
      <BannerPage :banner-type="{ type: 2, code: 75 }" />
    </div>
    <div class="mt-5 border-b p-b-1 bbc-str-d9d9d91a">
      <button
        class="mr-5"
        :class="[activatiType === 1 ? 'ct-f97316' : 'ct-ffffff33']"
        @click="!isLoading && handleListData(1)"
      >
        观看历史
      </button>
      <button :class="[activatiType === 2 ? 'ct-f97316' : 'ct-ffffff33']" @click="!isLoading && handleListData(2)">
        收藏视频
      </button>
    </div>
    <MediaAutoLoadGrid
      v-if="totalPage"
      :items="playCollectData"
      :is-delete="true"
      :is-show="false"
      :count="totalPage"
      :fetch="getPlayCollectNewData"
      @handle-delete="(val) => !isLoading && handleDelete(val)"
    />
    <div v-if="!totalPage && isLoading" animate-pulse py50>
      <div i-carbon:circle-dash ma animate-spin text-4xl />
    </div>
    <div
      v-if="!totalPage && !isLoading"
      class="w-full flex flex-col flex-justify-around flex-items-center text-center text-size-3"
    >
      <div class="my-20 w-full">
        <NuxtImg class="m-a w40% lg:w30%" src="/Empty.png" />
        <span ct-ffffffb3> 暂无相关资源 </span>
      </div>
      <NuxtLink to="/" ct-f97316> 返回主页 </NuxtLink>
    </div>
  </div>
  <UserBindPhone ref="bindPhoneRef" />
  <!-- 退出弹窗 -->
  <UserLogoutDialog ref="logoutDialogRef" />
</template>
