<script setup lang="ts">
import { useCommon } from '~/store/common'
import type { InputValidate } from '~/types'
import { userBindPhoneApi } from '~/api/common'

const { $message } = useNuxtApp()

interface BindPhoneType {
  phone: string
  phoneCode: string
}

const commonStore = useCommon()
const userStore = useUser()

// 弹窗显示
const visible = ref<boolean>(false)
// 按钮加载
const loading = ref<boolean>(false)
const secondLoading = ref<boolean>(false)
// 倒计时
const timer = ref<any>(undefined)
// 60s
const seconds = ref<number>(60)
// 验证手机号
const vidPhone = ref<InputValidate[]>([
  {
    required: true,
    message: '请输入手机号'
  },
  {
    message: '输入的手机号格式不正确',
    validator: (e: string) => {
      return useValidate().phone(e)
    }
  }
])
// 验证验证码
const vidPhoneCode = ref<InputValidate[]>([
  {
    required: true,
    message: '请输入验证码'
  },
  {
    message: '请输入正确的验证码',
    validator: (e: string) => {
      return useValidate().phoneCode(e)
    }
  }
])
// 提交表单数据
const bindPhoneForm = ref<BindPhoneType>({
  phone: '',
  phoneCode: ''
})
// 提交按钮状态
const submitBtnStatus = computed(() => {
  return (
    !!bindPhoneForm.value.phone &&
    !!bindPhoneForm.value.phoneCode &&
    useValidate().phone(bindPhoneForm.value.phone) &&
    useValidate().phoneCode(bindPhoneForm.value.phoneCode)
  )
})
// 重置表单
function resetForm() {
  bindPhoneForm.value.phoneCode = ''
  bindPhoneForm.value.phone = ''
  seconds.value = 60
  clearInterval(timer.value)
  timer.value = null
}
// 绑定手机号
async function handleBindPhone() {
  try {
    loading.value = true
    await userBindPhoneApi({
      telPhone: `+86-${bindPhoneForm.value.phone}`,
      verifyCode: bindPhoneForm.value.phoneCode
    })
    visible.value = false
    // $message.success('绑定手机成功')
  } catch (e) {
    console.log(e)
  } finally {
    await userStore.handleUserInfo()
    loading.value = false
  }
}
// 获取验证码
async function handleVerifyCode() {
  try {
    if (timer.value) {
      return
    }
    secondLoading.value = true
    await commonStore.handleCodePhone({ userPhone: `+86-${bindPhoneForm.value.phone}` })
    secondLoading.value = false
    timer.value = setInterval(() => {
      seconds.value--
      if (seconds.value === 0) {
        clearInterval(timer.value)
        timer.value = null
        seconds.value = 60
      }
    }, 1000)
  } catch (e) {
    clearInterval(timer.value)
    seconds.value = 60
    timer.value = null
  } finally {
    secondLoading.value = false
  }
}

defineExpose({
  visible
})
</script>

<template>
  <BaseDialog v-model="visible" dialog-class="w-84 lg:w-120" @before-close="resetForm">
    <div flex flex-col justify-between items-center mxa mb-7.5 mt-7.5 w-66 lg:w-96>
      <p mb-4 font-size-8 font-600>绑定手机号</p>
      <p mb-6 font-size-3.5 op50>绑定手机号后可以通过手机号找回账号</p>
      <BaseInput v-model="bindPhoneForm.phone" :validate="vidPhone" input-class="mb-7.25" placeholder="手机号" />
      <BaseInput
        v-model="bindPhoneForm.phoneCode"
        :validate="vidPhoneCode"
        input-class="mb-7.25"
        placeholder="验证码(6位)"
      >
        <template #append>
          <div w-60 cursor-pointer border-rd-12 py-0.75 text-center bl-fd5b03 ct-fd5b03 @click="handleVerifyCode">
            <div v-if="secondLoading" i-ep:loading ma animate-spin text-2xl />
            <p v-else-if="timer">
              {{ `${seconds}s` }}
            </p>
            <p v-else>获取验证码</p>
          </div>
        </template>
      </BaseInput>
      <BaseButton
        text="确定"
        round
        mb-4
        mt-10
        w-full
        :loading="loading"
        :disabled="!submitBtnStatus"
        @click="handleBindPhone()"
      />
    </div>
  </BaseDialog>
</template>
